import React, { useState, useMemo, useCallback, useEffect } from 'react';
import axios from 'axios';
import './App.css';
import PDFViewer from './components/PDFViewer';
import OI_ExtractedDataForm from './components/OI_ExtractedDataForm';
import OE_ExtractedDataForm from './components/OE_ExtractedDataForm';
import JobQueue from './components/JobQueue';
import LoadingOverlay from './components/LoadingOverlay';
import CompanyInput from './components/CompanyInput';
import { useCompanyJobs } from './hooks/useCompanyJobs';
import { useAuth } from './hooks/useAuth';
import { useFileUpload } from './hooks/useFileUpload';
import { useJobReview } from './hooks/useJobReview';
import { FaSignOutAlt } from 'react-icons/fa';
import Toast from './components/Toast';


axios.defaults.baseURL = 'https://docuextract.user.fr8labs.co/api';
axios.defaults.withCredentials = true;

// Add this function to set the Authorization header
const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common['Authorization'] = token;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
};

// Error handling for ResizeObserver
const originalError = console.error;
console.error = (...args) => {
  if (/ResizeObserver loop completed with undelivered notifications/.test(args[0])) {
    return;
  }
  originalError.call(console, ...args);
};

// Optimize child components
const ShipmentTypeSelection = React.memo(({ shipmentTypes, onSelect }) => (
  <div className="shipment-type-selection">
    <h2>Select Shipment Type</h2>
    <div className="shipment-type-grid">
      {shipmentTypes.map((type) => (
        <button
          key={type.id}
          className={`shipment-type-button ${type.available ? '' : 'coming-soon'}`}
          onClick={() => type.available && onSelect(type.id)}
          disabled={!type.available}
        >
          {type.name}
          {!type.available && <span className="coming-soon-label">Coming Soon</span>}
        </button>
      ))}
    </div>
  </div>
));

// At the top of the file, after the imports
const shipmentDirections = [
  { id: 'import', name: 'Import' },
  { id: 'export', name: 'Export' },
];

function App() {
  const [scale, setScale] = useState(1.0);
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [toastMessage, setToastMessage] = useState('');
  const { token, isLoggedIn, companyId, handleInviteCodeSubmit } = useAuth();
  const [selectedDocType, setSelectedDocType] = useState(null);
  const [shipmentType, setShipmentType] = useState(null);
  const [shipmentDirection, setShipmentDirection] = useState(null);

  // Memoize shipmentTypes to prevent unnecessary re-renders
  const shipmentTypes = useMemo(() => [
    { id: 'ocean', name: 'Ocean Shipment', available: true },
    { id: 'air', name: 'Air Shipment', available: false },
    { id: 'truck', name: 'Truck Shipment', available: false },
  ], []);

  const documentTypes = useMemo(() => [
    { id: 'notice_of_arrival', name: 'Notice of Arrival', availableFor: { type: 'ocean', direction: 'import' } },
    { id: 'booking_confirmation', name: 'Booking Confirmation', availableFor: { type: 'ocean', direction: 'export' }, available: true },
    { id: 'bill_of_lading', name: 'Bill of Lading', availableFor: { type: 'ocean', direction: 'export' }, available: false },
    { id: 'commercial_invoice', name: 'Commercial Invoice and Packing List', availableFor: { type: 'ocean', direction: 'export' }, available: false },
    { id: 'free_form', name: 'Free Form', availableFor: { type: 'ocean', direction: 'export' }, available: false },
  ], []);

  // Add this useEffect to set the token when the component mounts or when the token changes
  useEffect(() => {
    const token = localStorage.getItem('token');
    const fr8labsAuthToken = localStorage.getItem('fr8labs_auth_token');
    if (token) {
      axios.defaults.headers.common['Authorization'] = token;
    } else {
      delete axios.defaults.headers.common['Authorization'];
    }
    if (fr8labsAuthToken) {
      axios.defaults.headers.common['Fr8labs-Authorization'] = fr8labsAuthToken;
    } else {
      delete axios.defaults.headers.common['Fr8labs-Authorization'];
    }
  }, []);

  // Modify the useAuth hook to update the axios headers when the token changes
  useEffect(() => {
    if (token) {
      setAuthToken(token);
    } else {
      setAuthToken(null);
    }
  }, [token]);

  const { companyJobs, fetchCompanyJobs, updateJobStatus } = useCompanyJobs(token, isLoggedIn);
  const { selectedFile, status, onFileChange } = useFileUpload(
    companyId, 
    fetchCompanyJobs, 
    setToastMessage, 
    selectedDocType, 
    shipmentType, 
    shipmentDirection
  );
  const { reviewData, currentPdfUrl, isLoading: isReviewLoading, handleReview, onReviewComplete } = useJobReview(updateJobStatus);

  // Add this console.log
  console.log('Company jobs in App:', companyJobs);

  const handleBack = useCallback(() => {
    setStep(1);
    fetchCompanyJobs();
  }, [fetchCompanyJobs]);

  const handleRetry = useCallback(async (jobId) => {
    try {
      console.log(`Retrying job: ${jobId}`);
      const response = await axios.post(`/retry/${jobId}`);
      console.log('Retry response:', response.data);
      // The job status will be updated via the socket event
    } catch (error) {
      console.error('Error retrying job:', error);
    }
  }, []);

  const handleLogout = useCallback(() => {
    localStorage.clear();
    window.location.reload();
  }, []);

  const handleJobReview = useCallback(async (jobId) => {
    try {
      const reviewData = await handleReview(jobId);
      console.log('Review data in handleJobReview:', reviewData);
      setStep(2);
    } catch (error) {
      console.error('Error reviewing job:', error);
      setToastMessage('Error reviewing job. Please try again.');
    }
  }, [handleReview, setToastMessage]);

  const handleReviewComplete = useCallback((updatedData) => {
    setIsShipmentUrlLoading(true);
    console.log('Updated data in handleReviewComplete:', updatedData);
    onReviewComplete(updatedData)
      .then(() => {
        setIsShipmentUrlLoading(false);
        setStep(3);
      })
      .catch((error) => {
        setIsShipmentUrlLoading(false);
        setStep(3); // Still move to step 3 to show the error
        setToastMessage('Failed to create shipment. Please try again or contact support @ felix.lu@fr8labs.co');
      });
  }, [onReviewComplete]);

  const [isShipmentUrlLoading, setIsShipmentUrlLoading] = useState(true);

  // Optimize state update functions
  const handleShipmentTypeSelect = useCallback((type) => {
    console.log(`Selected shipment type: ${type}`);
    setShipmentType(type);
  }, []);

  const handleShipmentDirectionSelect = useCallback((direction) => {
    console.log(`Selected shipment direction: ${direction}`);
    setShipmentDirection(direction);
  }, []);

  // Memoize render functions
  const renderShipmentTypeSelection = useMemo(() => (
    <ShipmentTypeSelection shipmentTypes={shipmentTypes} onSelect={handleShipmentTypeSelect} />
  ), [shipmentTypes, handleShipmentTypeSelect]);

  const renderShipmentDirectionSelection = useMemo(() => (
    <div className="shipment-direction-selection">
      <h2>Select Shipment Direction</h2>
      <div className="shipment-direction-grid">
        {shipmentDirections.map((direction) => (
          <button
            key={direction.id}
            className="shipment-direction-button"
            onClick={() => handleShipmentDirectionSelect(direction.id)}
          >
            {direction.name}
          </button>
        ))}
      </div>
      <button onClick={() => setShipmentType(null)} className="back-button">
        Back to Shipment Type
      </button>
    </div>
  ), [handleShipmentDirectionSelect, setShipmentType]);

  const renderDocumentTypeSelection = useCallback(() => {
    const availableDocTypes = documentTypes.filter(
      (docType) => docType.availableFor.type === shipmentType && docType.availableFor.direction === shipmentDirection
    );

    return (
      <div className="document-type-selection">
        <h2>Select Document Type</h2>
        <div className="document-type-grid">
          {availableDocTypes.map((docType) => (
            <button
              key={docType.id}
              className={`document-type-button ${docType.available === false ? 'coming-soon' : ''}`}
              onClick={() => docType.available !== false && setSelectedDocType(docType.id)}
              disabled={docType.available === false}
            >
              {docType.name}
              {docType.available === false && <span className="coming-soon-label">Coming Soon</span>}
            </button>
          ))}
        </div>
        <button onClick={() => setShipmentDirection(null)} className="back-button">
          Back to Shipment Direction
        </button>
      </div>
    );
  }, [documentTypes, shipmentType, shipmentDirection, setSelectedDocType, setShipmentDirection]);

  const renderUploadSection = useCallback(() => {
    console.log(`Current shipment type: ${shipmentType}`);
    if (!shipmentType) {
      return renderShipmentTypeSelection;
    }

    if (!shipmentDirection) {
      return renderShipmentDirectionSelection;
    }

    if (!selectedDocType) {
      return renderDocumentTypeSelection();
    }

    // Render the file upload section for the selected document type
    return (
      <div className="file-upload-container">
        <h2>Upload {documentTypes.find(dt => dt.id === selectedDocType).name}</h2>
        <label htmlFor="file-upload" className="custom-file-upload">
          Click here to upload your file
        </label>
        <input
          id="file-upload"
          type="file"
          onChange={onFileChange}
          accept="application/pdf"
          style={{ display: 'none' }}
        />
        {selectedFile && <p className="file-name">Selected file: {selectedFile.name}</p>}
        <div className="status">{status}</div>
        <button onClick={() => setSelectedDocType(null)} className="back-button">
          Back to Document Selection
        </button>
      </div>
    );
  }, [shipmentType, shipmentDirection, selectedDocType, renderShipmentTypeSelection, renderShipmentDirectionSelection, renderDocumentTypeSelection, onFileChange, documentTypes, selectedFile, status, setSelectedDocType]);

  const renderStep = useMemo(() => {
    switch (step) {
      case 1:
        return (
          <div>
            {renderUploadSection()}
            <JobQueue jobs={companyJobs} onReview={handleJobReview} onRetry={handleRetry} />
          </div>
        );
      case 2:
        if (isReviewLoading || !reviewData) {
          return <LoadingOverlay message="Loading review data..." />;
        }
        const ExtractedDataFormComponent = 
          reviewData.shipment_type === 'ocean' && reviewData.shipment_direction === 'export'
            ? OE_ExtractedDataForm
            : OI_ExtractedDataForm;
        console.log('The reviewData shipment_type is: ', reviewData.shipment_type);
        console.log('The reviewData shipment_direction is: ', reviewData.shipment_direction);
        console.log('Review data in renderStep:', reviewData);
        return (
          <ExtractedDataFormComponent
            data={reviewData}
            onUpdate={(updatedData) => {
              console.log('Updated Data:', updatedData);
              handleReviewComplete(updatedData);
            }}
            onBack={handleBack}
          />
        );
        case 3:
          return (
            <div className="shipment-result">
              <h2>Shipment Submission Result</h2>
              
              {isShipmentUrlLoading ? (
                <LoadingOverlay message="Please wait as we are fetching the details of the created shipment..." />
              ) : (
                <div className={reviewData?.error ? "error-container" : "success-container"}>
                  {reviewData?.error ? (
                    <>
                      <div className="error-message">
                        <h3>Submission Failed</h3>
                        <p>{reviewData.error}</p>
                        {reviewData.errorDetails && (
                          <div className="error-details">
                            <p>Additional Details:</p>
                            <pre>{JSON.stringify(reviewData.errorDetails, null, 2)}</pre>
                          </div>
                        )}
                      </div>
                      <div className="error-actions">
                        <button onClick={handleBack} className="back-button">Back to Job Queue</button>
                        {reviewData.processError && (
                          <button onClick={() => handleReviewComplete(reviewData)} className="retry-button">
                            Retry Submission
                          </button>
                        )}
                      </div>
                    </>
                  ) : reviewData?.shipmentUrl ? (
                    <div className="success-message">
                      <p>Shipment created successfully!</p>
                      <p>
                        View it here:{' '}
                        <a href={reviewData.shipmentUrl} target="_blank" rel="noopener noreferrer">
                          {reviewData.shipmentUrl}
                        </a>
                      </p>
                      <button onClick={handleBack} className="back-button">Back to Job Queue</button>
                    </div>
                  ) : (
                    <div className="error-message">
                      <p>No shipment details available. Please try again or contact support.</p>
                      <button onClick={handleBack} className="back-button">Back to Job Queue</button>
                    </div>
                  )}
                </div>
              )}
            </div>
          );
      default:
        return null;
    }
  }, [step, renderUploadSection, companyJobs, handleJobReview, handleRetry, reviewData, isReviewLoading, handleBack, handleReviewComplete, isShipmentUrlLoading]);

  // Memoize renderContent
  const renderContent = useMemo(() => {
    if (!isLoggedIn) {
      return <CompanyInput onSubmit={async (inviteCode) => {
        setIsLoading(true);
        setLoadingMessage('Verifying invite code...');
        const success = await handleInviteCodeSubmit(inviteCode);
        if (success) {
          setLoadingMessage('Fetching company jobs...');
          await fetchCompanyJobs();
        } else {
          alert('Invalid invite code or error logging in. Please check with your Fr8labs Customer Service Officer.');
        }
        setIsLoading(false);
      }} />;
    }

    if (isLoading) {
      return <LoadingOverlay message={loadingMessage} />;
    }

    return (
      <>
        <div className="left-panel">
          {renderStep}
        </div>
        <div className="right-panel">
          {currentPdfUrl && <PDFViewer file={currentPdfUrl} scale={scale} setScale={setScale} />}
        </div>
      </>
    );
  }, [isLoggedIn, isLoading, handleInviteCodeSubmit, renderStep, currentPdfUrl, scale, fetchCompanyJobs, loadingMessage]);

  useEffect(() => {
    if (isLoggedIn) {
      fetchCompanyJobs();
    }
  }, [isLoggedIn, fetchCompanyJobs]);

  return (
    <div className="App">
      <header className="app-header">
        <div className="logo-container">
          <img src="/logo.png" alt="Fr8labs Logo" className="company-logo" />
        </div>
        <div className="app-title">
          <h1>Fr8labs DocuExtract</h1>
          <p className="slogan">Precision Data Extraction for Seamless Shipping</p>
        </div>
        {isLoggedIn && (
          <div className="user-info">
            <span>Welcome, {localStorage.getItem('companyName')}</span>
            <button onClick={handleLogout} className="logout-button">
              <FaSignOutAlt /> Logout
            </button>
          </div>
        )}
      </header>
      <div className="main-content">
        {renderContent}
      </div>
      {toastMessage && <Toast message={toastMessage} />}
    </div>
  );
}

export default App;