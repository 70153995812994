import React, { useState } from 'react';
import './InviteCodeInput.css';

function InviteCodeInput({ onSubmit }) {
  const [inviteCode, setInviteCode] = useState('');
  const [showInviteCode, setShowInviteCode] = useState(false);
  
  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(inviteCode);
  };

  const toggleShowInviteCode = () => {
    setShowInviteCode(!showInviteCode);
  };

  return (
    <div className="invite-code-input-overlay">
      <div className="invite-code-input-lightbox">
        <img src="/logo.png" alt="Fr8labs Logo" className="company-logo" />
        <h2>Welcome to Fr8labs DocuExtract</h2>
        <p>Please enter your invite code to access the platform.</p>
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <input
              type={showInviteCode ? "text" : "password"}
              value={inviteCode}
              onChange={(e) => setInviteCode(e.target.value)}
              placeholder="Paste your invite code here"
              required
            />
            <button type="button" onClick={toggleShowInviteCode}>
              {showInviteCode ? "Hide" : "Show"} Invite Code
            </button>
          </div>
          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
}

export default InviteCodeInput;