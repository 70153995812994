import { useState, useCallback, useEffect } from 'react';
import axios from 'axios';

export function useJobReview(updateJobStatus) {
  const [reviewData, setReviewData] = useState(null);
  const [currentPdfUrl, setCurrentPdfUrl] = useState(null);
  const [status, setStatus] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // Add this useEffect to set the token when the component mounts
  useEffect(() => {
    const token = localStorage.getItem('token');
    const fr8labsAuthToken = localStorage.getItem('fr8labs_auth_token');
    if (token) {
      axios.defaults.headers.common['Authorization'] = token;
    } else {
      delete axios.defaults.headers.common['Authorization'];
    }
    if (fr8labsAuthToken) {
      axios.defaults.headers.common['Fr8labs-Authorization'] = fr8labsAuthToken;
    } else {
      delete axios.defaults.headers.common['Fr8labs-Authorization'];
    }
  }, []);

  const handleReview = useCallback(async (jobId) => {
    setIsLoading(true);
    setStatus('Fetching job data...');
    try {
      console.log(`Fetching review for job ${jobId}`);
      // Add the adminUserId to the headers for use in the backend API because different companies should have different admin users.
      const adminUserId = localStorage.getItem('adminUserId');
      if (adminUserId) {
        axios.defaults.headers.common['Admin-User-Id'] = adminUserId;
      }
      
      const [reviewResponse, pdfResponse] = await Promise.all([
        axios.get(`/review/${jobId}`),
        axios.get(`/file_path/${jobId}`)
      ]);
      
      console.log('Review response:', reviewResponse.data);
      const reviewDataWithTypes = { 
        ...reviewResponse.data, 
        job_id: jobId,
        shipment_type: reviewResponse.data.shipment_type,
        shipment_direction: reviewResponse.data.shipment_direction
      };
      setReviewData(reviewDataWithTypes);
      
      // Use the full URL for the PDF
      const pdfUrl = `${axios.defaults.baseURL}/pdf/${jobId}?t=${Date.now()}`;
      console.log('Setting PDF URL:', pdfUrl);
      setCurrentPdfUrl(pdfUrl);
      
      if (reviewResponse.data.file_path !== pdfResponse.data.file_path) {
        console.warn('Mismatch between review data and PDF file path');
        setStatus('Warning: Possible mismatch between review data and PDF');
      } else {
        setStatus('');
      }
      setIsLoading(false);
      return reviewDataWithTypes;
    } catch (error) {
      console.error('Error fetching job for review:', error);
      setStatus('Error fetching job for review: ' + error.message);
      setIsLoading(false);
      throw error;
    }
  }, []);

  const onReviewComplete = useCallback(async (updatedData) => {
    setStatus('Processing and submitting shipment...');
    try {
      console.log('Updated data in onReviewComplete:', updatedData);
      
      // Process the shipment
      const processResponse = await axios.post('/process', updatedData);
      if (!processResponse.data) {
        const error = new Error('Failed to process shipment data');
        error.details = 'No response data received from processing';
        throw error;
      }

      // Submit the shipment
      const submitResponse = await axios.post('/submit', processResponse.data);
      if (!submitResponse.data?.data?.createShipment) {
        const error = new Error('Failed to create shipment');
        error.details = 'No shipment data received from submission';
        throw error;
      }

      const { shipmentNo, shipmentId, billofLadingNo } = submitResponse.data.data.createShipment;
      
      // Determine the shipment URL
      let shipmentUrl;
      if (updatedData.shipment_type === 'ocean' && updatedData.shipment_direction === 'import') {
        shipmentUrl = `https://user.fr8labs.co/en/shipment/ocean-import/${shipmentNo}`;
      } else {
        shipmentUrl = `https://user.fr8labs.co/en/shipment/ocean-export/${shipmentNo}`;
      }

      setReviewData({ 
        ...updatedData, 
        shipmentUrl, 
        shipmentNo, 
        shipmentId, 
        billofLadingNo,
        error: null // Clear any previous errors
      });
      setStatus('Shipment submitted successfully');
      
      // Update job status if job_id exists
      if (updatedData.job_id) {
        await axios.post(`/update_job/${updatedData.job_id}`, {
          status: 'submitted',
          shipmentNo,
          shipmentUrl,
          shipmentId,
          billofLadingNo
        });
        updateJobStatus(updatedData.job_id, 'submitted', shipmentNo, shipmentId, billofLadingNo);
      }
    } catch (error) {
      console.error('Error in onReviewComplete:', error);
      
      // Enhanced error message handling
      let errorMessage = 'An unexpected error occurred';
      if (error.response) {
        // Handle axios error response
        errorMessage = error.response.data?.message || 
                      error.response.data?.error || 
                      `Server error: ${error.response.status} - ${error.response.statusText}`;
      } else if (error.details) {
        // Handle our custom error details
        errorMessage = `${error.message}: ${error.details}`;
      } else if (error.message) {
        errorMessage = error.message;
      }

      setStatus(`Error: ${errorMessage}`);
      
      // Update reviewData with detailed error information
      setReviewData({
        ...updatedData,
        error: errorMessage,
        errorDetails: error.response?.data || error.details || null,
        shipmentUrl: null,
        processError: error.response?.status === 400 // Flag specifically for process errors
      });
      
      // Keep the existing job status update logic
      if (updatedData.job_id) {
        await axios.post(`/update_job/${updatedData.job_id}`, {
          status: 'failed',
          error: errorMessage,
          errorDetails: error.response?.data || error.details || null
        }).catch(updateError => {
          console.error('Failed to update job status:', updateError);
        });
        updateJobStatus(updatedData.job_id, 'failed');
      }
      
      // Make sure the error is thrown for the component to handle
      throw error;
    }
  }, [updateJobStatus]);

  return { reviewData, currentPdfUrl, status, isLoading, handleReview, onReviewComplete };
}